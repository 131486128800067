var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-6",attrs:{"color":"white"}},[_c('v-data-table',{attrs:{"single-expand":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.transactions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.agent.userName))]}},{key:"item.depositsSuccess",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.depositsSuccess)))]}},{key:"item.cancelledDeposits",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.cancelledDeposits)))]}},{key:"item.withdrawalsDone",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.withdrawalsDone)))]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.totalAmount)))]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination_btn pagination-btn--previous",attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.prev()}}},[_c('v-icon',{staticClass:"pagination_btn-icon mr-2"},[_vm._v("mdi-chevron-left")]),_vm._v("Previous ")],1),_c('p',{staticClass:"pagination_total-page"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages)+" ("+_vm._s(_vm.totalTransactions)+" results)")]),_c('button',{staticClass:"pagination_btn pagination-btn--next",attrs:{"disabled":_vm.currentPage === _vm.totalPages || _vm.currentPage > _vm.totalPages},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next"),_c('v-icon',{staticClass:"pagination_btn-icon ml-2"},[_vm._v("mdi-chevron-right")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }